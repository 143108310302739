<template>
  <div class="wrapper">
    <row>
      <column
        offset-xs="0"
        offset-sm="2"
        offset-md="3"
        xs="12"
        sm="8"
        md="6"
        class="mb-3">
        <form
          v-if="isMounted"
          :action="action"
          method="post"
          class="login-page-form mb-3">
          <h1 class="ff-flama fs-xl fw-light mb-4">{{ __('already.subscriber') }}</h1>
          <div
            :class="['alert alert-danger error mb-3', { hide: !errorLogin }]">
            <p
              :class="['mb-1', { hide: !invalidCredential }]">{{ __('wrong.credential') }}</p>
            <p
              :class="['mb-1', { hide: !concurrencyExceed }]">{{ __('concurrent.session.exceeded') }}</p>
            <p
              :class="['mb-1', { hide: !emailNotVerified }]">{{ __('email.not.verified') }}</p>
            <a
              :class="['text-reset fw-medium td-underline', { hide: hideResetPassword }]"
              href="/subscription/password/">{{ __('reset.password') }}</a>
          </div>
          <fieldset>
            <input
              :value="csrfToken"
              type="hidden"
              name="_csrf">
            <label
              :class="{ invalid: invalidCredential }"
              for="username">{{ __('username') }}</label>
            <input
              id="username"
              :placeholder="__('enter.yours')"
              :class="['form-control mb-3', { invalid: invalidCredential }]"
              type="text"
              value=""
              name="username">
            <label
              :class="{ invalid: invalidCredential }"
              for="password">{{ __('password') }}</label>
            <input
              id="password"
              :placeholder="__('enter.yours')"
              :class="['form-control mb-4', { invalid: invalidCredential }]"
              type="password"
              name="password">
            <input
              id="login_button"
              :value="__('log.in')"
              type="submit"
              class="btn btn-primary mr-3 float-left io-subscription-block">
            <div class="ff-flama fs-sm">
              <div
                v-if="!hideNewUser">
                <span>{{ __('new.user') }}</span>
                <a
                  href="/subscription/"
                  class="fw-bold text-reset io-subscription-button">
                  {{ __('subscribe.now') }}
                </a>
              </div>
              <a
                v-if="!hideForgottenPassword"
                href="/subscription/password/"
                class="text-reset">
                {{ __('forgot.password') }}
              </a>
            </div>
          </fieldset>
        </form>
        <p
          v-html="__('loginpage.visit.us')"
          class="text-right"/>
      </column>
    </row>
  </div>
</template>
<script>

import { v4 as uuidv4 } from 'uuid';
import cookie from 'cookie';
import { Page } from 'global-components';
import { EmbedMixin } from 'global-mixin';

export default {
  name: 'login-page',
  extends: Page,
  mixins: [EmbedMixin],
  data() {
    return {
      isMounted: false,
      errorLogin: this.$route.query.login_error,
      invalidCredential: this.$route.query.login_error === '401',
      concurrencyExceed: this.$route.query.login_error === '429',
      emailNotVerified: this.$route.query.login_error === '412',
      hideResetPassword: (this.$store.state.publication.hideForgottenPassword || this.$route.query.login_error === '429' || this.$route.query.login_error === '412'),
      hideForgottenPassword: this.$store.state.publication.hideForgottenPassword,
      hideNewUser: this.$store.state.publication.hideNewUser,
    };
  },
  computed: {
    action() {
      return `${this.getLoginEndpoint()}?target=${this.getLoginTarger()}`;
    },
    csrfToken() {
      return this.loadCsrfToken();
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    loadCsrfToken() {
      let csrfToken = cookie.parse(document.cookie)['CSRF-TOKEN'];
      if (csrfToken === undefined) {
        csrfToken = uuidv4();
        document.cookie = `CSRF-TOKEN=${csrfToken};path=/`;
      }
      return csrfToken;
    },
    getLoginTarger() {
      const target = this.$route.query.target || this.$route.path;
      return encodeURIComponent(decodeURIComponent(target));
    },
    getLoginEndpoint() {
      return this.$store.state.nhstAuth.loginEndpoint;
    }
  },
  embed(head) {
    head.add(
      `<script type="text/javascript">
          var value = "; " + document.cookie;
          var parts = value.split("; oneid_info=");
          if (parts.length >= 2) {
            var vpwId = parts.pop().split(";").shift();
            if (vpwId) {
              window.location.href = '/';
            }
          }
        <` + `/script>`
    );
  }
};

</script>
